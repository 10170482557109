import React, { useState } from 'react';
import '../styles.css';

function Tool() {
  const [selectedLayerImages, setSelectedLayerImages] = useState([]);
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [nftImageUrl, setNftImageUrl] = useState('');
  const [nftId, setNftId] = useState('');

  const layerImageUrls = [
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Smoke.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Tan.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Ape.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Brown.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Void.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Yellow.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Angel.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Mamba.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Oscar.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/White.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Black%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Leopard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Red%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Dark.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Platinum.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Red.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Blue%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Galaxy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Green%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Trippy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Zombie.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Matrix.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Magma.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcupone/Dragon.png',
    
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Smoke.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Tan.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Ape.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Brown.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Void.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Yellow.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Angel.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Mamba.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Oscar.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/White.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Black%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Leopard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Red%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Dark.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Platinum.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Red.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Blue%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Galaxy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Green%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Trippy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Zombie.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Matrix.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Magma.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/beer/Dragon.png',

    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Smoke.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Tan.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Ape.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Brown.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Void.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Yellow.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Angel.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Mamba.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Oscar.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/White.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Black%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Leopard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Red%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Dark.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Platinum.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Red.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Blue%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Galaxy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Green%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Trippy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Zombie.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Matrix.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Magma.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/cards/Dragon.png',

    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Smoke.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Tan.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Ape.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Brown.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Void.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Yellow.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Angel.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Mamba.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Oscar.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/White.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Black%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Leopard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Red%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Dark.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Platinum.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Red.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Blue%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Galaxy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Green%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Trippy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Zombie.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Matrix.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Magma.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gn/Dragon.png',

    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Smoke.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Smoke.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Tan.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Ape.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Brown.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Void.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Yellow.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Angel.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Mamba.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Oscar.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/White.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Black%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Leopard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Red%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Dark.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Platinum.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Red.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Blue%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Galaxy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Green%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Trippy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Zombie.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Matrix.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Magma.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gun/Dragon.png',

    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Smoke.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Tan.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Ape.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Brown.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Void.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Yellow.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Angel.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Mamba.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Oscar.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/White.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Black%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Leopard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Red%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Dark.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Platinum.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Red.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Blue%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Galaxy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Green%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Trippy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Zombie.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Matrix.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Magma.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/diamond/Dragon.png',

    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Smoke.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Tan.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Ape.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Brown.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Void.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Yellow.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Angel.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Mamba.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Oscar.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/White.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Black%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Leopard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Red%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Dark.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Platinum.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Red.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Blue%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Galaxy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Green%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Trippy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Zombie.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Matrix.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Magma.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/belt/Dragon.png',

    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Smoke.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Tan.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Ape.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Brown.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Void.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Yellow.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Angel.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Mamba.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Oscar.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/White.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Black%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Leopard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Red%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Dark.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Platinum.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Red.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Blue%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Galaxy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Green%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Trippy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Zombie.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Matrix.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Magma.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/gmcuptwo/Dragon.png',

    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Smoke.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Tan.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Ape.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Brown.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Void.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Yellow.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Angel.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Mamba.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Oscar.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/White.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Black%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Leopard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Red%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Dark.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Platinum.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Red.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Blue%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Galaxy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Green%20motherboard.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Trippy.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Zombie.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Matrix.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Magma.png',
    'https://raw.githubusercontent.com/SOLamyy/new-puff/main/rum/Dragon.png',


  ];

  const customLayerNames = [
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'GM Cup',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Beer',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'Card',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'GN',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Gun',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Diamond Hand',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'Championship Belt',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'GM Cup New',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
    'Rum',
  ];

  const fetchNFT = () => {
    setSelectedLayerImages([]); 
    if (nftId === '') {
      alert('Please enter an NFT ID');
      return;
    }

    const imageUrl = `https://raw.githubusercontent.com/SOLamyy/Puffsterz/main/id/${nftId}.png`;

    fetch(imageUrl)
      .then(response => {
        if (!response.ok) {
          if (response.status === 404) {
            setModalMessage('ID NOT FOUND');
            setShowModal(true);
          } else {
            throw new Error('Error fetching NFT');
          }
        } else {
          const nftContainer = document.getElementById('nftContainer');
          const existingOverlay = document.querySelector('.layer-overlay');
          if (existingOverlay) {
            nftContainer.removeChild(existingOverlay);
          }
          setNftImageUrl(imageUrl);
          addButton();
        }
      })
      .catch(error => {
        setModalMessage(error.message);
        setShowModal(true);
      });
  };

  

  const addButton = () => {
    const dropdownContainer = document.createElement('div');
    dropdownContainer.classList.add('dropdown-container');
    const layerDropdown = document.createElement('select');
    layerDropdown.id = 'layer-dropdown';

    const defaultOption = document.createElement('option');
    defaultOption.value = '';
    defaultOption.textContent = 'CHOOSE YOUR LAYER';
    layerDropdown.appendChild(defaultOption);

    const existingDropdown = document.getElementById('layer-dropdown');
    if (existingDropdown) {
      existingDropdown.parentNode.remove();
    }

    
 const smokeId = [7,284,973,1155,1488,348,1780,842,1188,283,729,23,398,1433,1800,1201,296,772,443,745,2,1511,792,1132,878,536,310,1131,576,633,531,100,623,763,1618,1078,1598,1670,192,233,176,1614,838,1258,724,680,99,183,1855,992,180,1095,42,344,190,1116,395,1148,1083,416,581,711,375,384,1274,458,947,74,68,957,640,824,1744,1210,788,174,805,1002,927,1149,1742,1771,786,1397,935,1658,851,1783,1,793,1190,27,911,1325,1725,137,1679,857,1829,389,1424,509,365,1423,282,47,1102,1864,971,1497,1680,1565,1507,1090,109,1461,540,1030,1541,784,634,1176,782,1221,595,835,565,914,820,1672,1830,1061,572,336,720,461,1272,660,783,1868,980,1442,1094,374,143,1822,117,1017,717,670,596]
if (smokeId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[0];
  cupOption.textContent = customLayerNames[0];
  layerDropdown.appendChild(cupOption);

  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[24];
  beerOption.textContent = customLayerNames[24];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[48];
  hoshOption.textContent = customLayerNames[48];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[72];
  deadOption.textContent = customLayerNames[72];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[97];
  ticketOption.textContent = customLayerNames[97];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[121];
  diamondOption.textContent = customLayerNames[121];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[145];
  beltOption.textContent = customLayerNames[145];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[169];
  newCupOption.textContent = customLayerNames[169];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[193];
  rumOption.textContent = customLayerNames[193];
  layerDropdown.appendChild(rumOption);
  

}
const tanId = [214,157,503,972,1048,543,1707,1419,1572,1441,1790,79,224,1001,1629,601,1574,167, 683, 1315, 1253, 391, 1376, 1426, 354, 948, 875, 1323,1171,489, 392, 830, 303, 1651, 1554, 506, 737, 1747, 1867, 1475, 1534,579, 1810, 995, 1436, 1645, 1289, 1314, 90, 1535, 755, 261,15911432, 508, 1570, 996, 834, 1337, 582, 1685, 810, 1283, 693, 194546, 562, 226, 1714, 431, 752, 1352, 1536, 1115, 204, 1872, 748, 51, 662, 532, 1669, 253, 567,1237, 825, 1557, 1662, 1394, 1842, 684, 538, 727, 882, 1738, 1575,1174, 200, 1105, 1588, 1167, 1004, 64, 1628, 654, 794, 1470, 1838,1493, 519, 357, 1207, 21, 622, 1505, 381, 1310, 1445, 107, 1227, 671, 98]
if (tanId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[1];
  cupOption.textContent = customLayerNames[1];
  layerDropdown.appendChild(cupOption);

  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[25];
  beerOption.textContent = customLayerNames[25];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[49];
  hoshOption.textContent = customLayerNames[49];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[73];
  deadOption.textContent = customLayerNames[73];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[98];
  ticketOption.textContent = customLayerNames[98];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[122];
  diamondOption.textContent = customLayerNames[122];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[146];
  beltOption.textContent = customLayerNames[146];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[170];
  newCupOption.textContent = customLayerNames[170];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[194];
  rumOption.textContent = customLayerNames[194];
  layerDropdown.appendChild(rumOption);
  


}
const apeId = [1322, 624, 757, 1113, 1089, 970, 1775, 245, 1187, 1716, 198, 1798, 1137, 639, 260, 892, 1366,1128, 1074, 1455, 424, 1260, 1619, 1516, 210, 1652, 837, 452, 1099, 1408, 1194, 728, 1654, 1216, 58,1385, 298, 930, 1543, 1636, 140, 1523, 1818, 397, 1266, 147, 705, 232, 1391, 1335, 1697, 1781, 9061125, 149, 1698, 1170, 1610, 1311458, 341, 330, 1466, 106, 706326, 674, 462, 1413, 1492, 1545, 979, 488, 499, 1806, 127, 196708, 1084, 213, 905, 1003, 1295, 1832, 10, 1063, 652, 1597, 168,827, 1213, 141, 1291, 789, 1375, 1502, 281, 1663, 49, 856,96,184, 707, 1608, 1585, 1671, 1580,1359, 1803, 698, 1626, 287, 484,696, 666, 883, 269, 322, 314,60, 301, 735, 1860, 590, 1374,22, 1883, 537, 1634, 1581, 61]
if (apeId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[2];
  cupOption.textContent = customLayerNames[2];
  layerDropdown.appendChild(cupOption);

  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[26];
  beerOption.textContent = customLayerNames[26];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[50];
  hoshOption.textContent = customLayerNames[50];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[74];
  deadOption.textContent = customLayerNames[74];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[99];
  ticketOption.textContent = customLayerNames[99];
  layerDropdown.appendChild(ticketOption);
  
  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[123];
  diamondOption.textContent = customLayerNames[123];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[147];
  beltOption.textContent = customLayerNames[147];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[171];
  newCupOption.textContent = customLayerNames[171];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[195];
  rumOption.textContent = customLayerNames[195];
  layerDropdown.appendChild(rumOption);
  


}
const brownId = [1715, 1709, 103, 217, 1774, 1468, 1736, 550, 769, 247, 962,128, 377, 385, 1239, 795, 632, 1024, 936, 162, 1479, 468, 1276,366, 227, 1743, 268, 1141, 1415, 1287, 520, 1785, 1690, 379, 734,790, 32, 526, 528, 994, 65, 449, 1871, 589, 31, 1512, 54,1401, 1862, 1647, 1469, 1459, 113, 535, 482, 229, 1503, 1630, 1369,574, 767, 621, 1197, 333, 739, 1719, 884, 1403, 139, 1734, 1182,1817, 1421, 1587, 421, 918, 1154, 249, 278, 1269, 750, 1143, 120,195, 799, 259, 1053, 415, 14, 1123, 1812, 1039, 1540, 1550, 1250,1638, 743, 266, 1344, 524, 1835, 908, 688, 559, 791, 271, 1702,130, 251, 380, 215, 1387, 323, 1365, 833, 1355, 568]
if (brownId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[3];
  cupOption.textContent = customLayerNames[3];
  layerDropdown.appendChild(cupOption);


  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[27];
  beerOption.textContent = customLayerNames[27];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[51];
  hoshOption.textContent = customLayerNames[51];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[75];
  deadOption.textContent = customLayerNames[75];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[100];
  ticketOption.textContent = customLayerNames[100];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[124];
  diamondOption.textContent = customLayerNames[124];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[148];
  beltOption.textContent = customLayerNames[148];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[172];
  newCupOption.textContent = customLayerNames[172];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[196];
  rumOption.textContent = customLayerNames[196];
  layerDropdown.appendChild(rumOption);
  

}
const voidId = [1768, 902, 886, 1109, 1251, 817, 1849, 1765, 254, 659, 18,444, 855, 349, 1071, 570, 1196, 613, 1261, 97, 1265, 1623, 297,1038, 496, 1437, 494, 1886, 615, 1027, 1208, 687, 961, 1330, 776,1087, 877, 507, 903, 433, 762, 71, 1607, 843, 900, 252, 1495,171, 1264, 1566, 1551, 367, 19, 80, 445, 854, 1054, 4, 1191,1809, 1854, 1611, 1407, 1604, 1166, 893, 420, 1431, 778, 549, 1711,1016, 530, 742, 166, 286, 1786, 1349, 1202, 1361, 203, 199, 982,473, 812, 1183, 1635, 831, 807, 599, 1281, 1235, 1584, 643, 1447,340, 1463, 874, 541, 828, 612, 1500, 1345, 1091, 1688, 716, 1726,1556, 916, 603, 1220, 1430, 189]
if (voidId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[4];
  cupOption.textContent = customLayerNames[4];
  layerDropdown.appendChild(cupOption);


  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[28];
  beerOption.textContent = customLayerNames[28];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[52];
  hoshOption.textContent = customLayerNames[52];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[76];
  deadOption.textContent = customLayerNames[76];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[101];
  ticketOption.textContent = customLayerNames[101];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[125];
  diamondOption.textContent = customLayerNames[125];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[149];
  beltOption.textContent = customLayerNames[149];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[173];
  newCupOption.textContent = customLayerNames[173];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[197];
  rumOption.textContent = customLayerNames[197];
  layerDropdown.appendChild(rumOption);
  


}
const yellowId = [569, 880, 1762, 70, 747, 765, 316, 525, 1268, 1076, 1312,932, 1023, 8, 466, 1525, 849, 1613, 55, 1263, 954, 48, 1797,411, 1562, 1147, 1014, 1000, 1252, 607, 1098, 965, 978, 764, 136,1582, 351, 785, 505, 1103, 679, 475, 1333, 721, 1298, 669, 1682,405, 626, 1081, 1193, 1460, 3, 1388, 1632, 257, 960, 436, 1879,1172,328,922,919,1243,121,901, 75, 564, 715, 1371.1467,1837, 1242, 1706, 591, 1209, 262,1267, 625, 1307, 1815, 123, 1058, 1086, 56, 1861, 1306, 1691, 1756,885, 1649, 487, 1219, 1051, 586, 1650, 1135, 667, 1020, 575, 1769,1319, 1309, 1075, 929, 839, 1300, 1277]
if (yellowId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[5];
  cupOption.textContent = customLayerNames[5];
  layerDropdown.appendChild(cupOption);

  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[29];
  beerOption.textContent = customLayerNames[29];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[53];
  hoshOption.textContent = customLayerNames[53];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[77];
  deadOption.textContent = customLayerNames[77];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[102];
  ticketOption.textContent = customLayerNames[102];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[126];
  diamondOption.textContent = customLayerNames[126];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[150];
  beltOption.textContent = customLayerNames[150];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[174];
  newCupOption.textContent = customLayerNames[174];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[198];
  rumOption.textContent = customLayerNames[198];
  layerDropdown.appendChild(rumOption);
  

}
const angelId = [439, 403, 1746, 1723, 699, 446, 1869, 486, 1331, 419, 1841,148, 1478, 1106, 953, 1510, 35, 115, 1782, 808, 1377, 915, 1134,86, 105, 329, 201, 1569, 816, 1025, 1851, 88, 608, 583, 399,30, 1546, 1878, 554, 1156, 1434, 899, 16, 356, 390, 1138, 1045,926, 1729, 1784, 132, 944, 1362, 938, 527, 985, 382, 1826, 202,1848, 92, 1428, 1700, 57, 933, 1007, 1696, 829, 246, 573, 658,1552, 1294, 593, 1392, 1150, 1290, 353, 1092, 1005, 529, 1480, 692,11, 208, 293, 1304, 542, 1223, 1036, 1200, 974, 1538, 1577, 40,771, 277, 1708, 917, 358, 585, 563, 25]
if (angelId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[6];
  cupOption.textContent = customLayerNames[6];
  layerDropdown.appendChild(cupOption);

  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[30];
  beerOption.textContent = customLayerNames[30];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[54];
  hoshOption.textContent = customLayerNames[54];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[78];
  deadOption.textContent = customLayerNames[78];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[103];
  ticketOption.textContent = customLayerNames[103];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[127];
  diamondOption.textContent = customLayerNames[127];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[151];
  beltOption.textContent = customLayerNames[151];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[175];
  newCupOption.textContent = customLayerNames[175];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[199];
  rumOption.textContent = customLayerNames[199];
  layerDropdown.appendChild(rumOption);

}
const mambaId = [1606, 1129, 1625, 956, 1301,83,1717,172,1249,1620,52,347,502,402,1484,1674,1284,1471,802,1474,292,863,396,1340,1728,135,1833,1701,1644,1373, 1673, 556, 129, 1617, 1766,112, 777, 969, 811, 1847, 1145,1586, 533, 387, 871, 637, 600,958, 5, 920, 119, 1583, 325, 453, 1741, 69, 832, 300, 118,1425, 1060, 1351, 1881, 1547, 1100, 1262, 580, 1793, 1561, 959, 483,1398, 1180, 1811, 235, 1316, 368, 1508, 76, 455, 1694, 1882, 241,102, 1778, 1801, 373, 1858, 457, 1065, 1491, 315, 941, 516,1449,1327, 1247, 1161, 853, 691]
if (mambaId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[7];
  cupOption.textContent = customLayerNames[7];
  layerDropdown.appendChild(cupOption);


  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[31];
  beerOption.textContent = customLayerNames[31];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[55];
  hoshOption.textContent = customLayerNames[55];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[79];
  deadOption.textContent = customLayerNames[79];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[104];
  ticketOption.textContent = customLayerNames[104];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[128];
  diamondOption.textContent = customLayerNames[128];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[152];
  beltOption.textContent = customLayerNames[152];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[176];
  newCupOption.textContent = customLayerNames[176];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[200];
  rumOption.textContent = customLayerNames[200];
  layerDropdown.appendChild(rumOption);
  


}
const oscarId = [1452, 847, 82, 1789, 534, 126, 898, 1773, 448, 1119, 1372,414, 1163, 400, 1206, 1189, 401, 690, 320, 775, 1839, 867, 1236,1749, 305, 1666, 1656, 1748, 1454, 1668, 211, 1118, 1877, 517, 1044,1722, 378, 1579, 1069, 1875, 787, 1739, 1740, 1271, 434, 95, 404,895, 1184, 1615, 1396, 1059, 1532, 1299, 1029, 1686, 656, 225, 1718,85, 1761, 467, 1787, 1422, 1356, 1609, 1341, 1164, 1204, 1568, 1465,650, 454, 1347, 78, 518, 406, 321, 1823, 492, 870, 1110, 714,1114, 124, 1093, 756, 1593, 39, 896, 1472, 218]
if (oscarId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[8];
  cupOption.textContent = customLayerNames[8];
  layerDropdown.appendChild(cupOption);


  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[32];
  beerOption.textContent = customLayerNames[32];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[56];
  hoshOption.textContent = customLayerNames[56];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[80];
  deadOption.textContent = customLayerNames[80];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[105];
  ticketOption.textContent = customLayerNames[105];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[129];
  diamondOption.textContent = customLayerNames[129];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[153];
  beltOption.textContent = customLayerNames[153];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[177];
  newCupOption.textContent = customLayerNames[177];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[201];
  rumOption.textContent = customLayerNames[201];
  layerDropdown.appendChild(rumOption);
  


}
const whiteId = [491, 726, 1616, 1548, 664, 1279, 571, 1788, 1056, 165, 1142,1332, 175, 1527, 1364, 150, 1386, 1594, 700, 800, 1802, 1481, 1446,952, 1259, 1136, 1302, 1139, 334, 1404, 1215, 46, 876, 1101, 212,1159, 759, 331, 1444, 197, 1224, 976, 1338, 26, 1827, 678, 999,479, 1165, 741, 1009, 1130, 1873, 1558, 1320, 1288, 522, 713, 1234,697, 1464, 360, 1777, 924, 1343, 1286, 1514, 1067, 1111, 1884, 702,182, 510, 1880, 1836, 1217, 845, 37, 781, 561, 481, 1732, 760,592, 879, 440, 993, 1117]
if (whiteId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[9];
  cupOption.textContent = customLayerNames[9];
  layerDropdown.appendChild(cupOption);

  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[33];
  beerOption.textContent = customLayerNames[33];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[57];
  hoshOption.textContent = customLayerNames[57];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[81];
  deadOption.textContent = customLayerNames[81];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[106];
  ticketOption.textContent = customLayerNames[106];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[130];
  diamondOption.textContent = customLayerNames[130];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[154];
  beltOption.textContent = customLayerNames[154];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[178];
  newCupOption.textContent = customLayerNames[178];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[202];
  rumOption.textContent = customLayerNames[202];
  layerDropdown.appendChild(rumOption);
  


}
const blackId = [1856, 682, 1539, 722, 733, 512, 1348, 611, 1563, 751, 998,1776, 1336, 363, 1857, 1079, 897, 1225, 256, 606, 1681, 1185, 694,67, 248, 1173, 744, 866, 1104, 1885, 220, 1678, 345, 59, 1853,1050, 963, 578, 1395, 818, 1599, 151, 822, 1096, 598, 1496, 1814,1339, 1596, 1735, 730, 1205, 655, 1477, 264, 472, 1192, 1324, 275,43, 438, 1808, 988, 558, 187, 850, 1211, 327, 145, 427, 1763,521, 629, 191, 732, 29, 1642, 1804, 81, 1852, 234, 1559, 355,231, 409, 237, 646]
if (blackId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[10];
  cupOption.textContent = customLayerNames[10];
  layerDropdown.appendChild(cupOption);


  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[34];
  beerOption.textContent = customLayerNames[34];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[58];
  hoshOption.textContent = customLayerNames[58];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[82];
  deadOption.textContent = customLayerNames[82];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[107];
  ticketOption.textContent = customLayerNames[107];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[131];
  diamondOption.textContent = customLayerNames[131];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[155];
  beltOption.textContent = customLayerNames[155];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[179];
  newCupOption.textContent = customLayerNames[179];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[203];
  rumOption.textContent = customLayerNames[203];
  layerDropdown.appendChild(rumOption);
  

}
const leopardId = [1412, 258, 432, 407, 1745, 1705, 819, 181, 1448, 1438, 279,1342, 425, 291, 991, 1158, 703, 1127, 1622, 66, 609, 1805, 740,907, 891, 302, 493, 44, 1041, 1850, 889, 239, 1257, 339, 114,804, 1643, 1411, 1489, 38, 1124, 672, 1731, 1018, 1757, 1121,274,1576,1010, 500, 1866, 645, 156, 164, 1517, 1248, 272, 511,1863,1501,844, 1383, 766, 955, 1820, 1405, 1246, 1888, 304, 1082, 1549, 1162,1357, 945, 1560, 801, 1231, 1046, 138]
if (leopardId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[11];
  cupOption.textContent = customLayerNames[11];
  layerDropdown.appendChild(cupOption);


  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[35];
  beerOption.textContent = customLayerNames[35];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[59];
  hoshOption.textContent = customLayerNames[59];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[83];
  deadOption.textContent = customLayerNames[83];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[108];
  ticketOption.textContent = customLayerNames[108];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[132];
  diamondOption.textContent = customLayerNames[132];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[156];
  beltOption.textContent = customLayerNames[156];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[180];
  newCupOption.textContent = customLayerNames[180];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[204];
  rumOption.textContent = customLayerNames[204];
  layerDropdown.appendChild(rumOption);
  


}
const redmId = [865,1198,437,1795,1637,1270,1504,1399,796,673,1273,1382,15, 704, 1233, 723, 1753, 1241,376, 1834, 1354, 620, 1296, 1032,1754, 209, 1553, 689, 1360, 1631, 1222, 1602, 408, 116, 243, 1660,1689, 1370, 1292, 668, 1393, 610, 1676, 418, 566, 852, 223, 1255,426, 1573, 1068, 1429, 87, 221, 584, 1318, 1080, 324, 1720, 1015,471, 806, 1595, 24, 159, 553, 240, 1483, 319, 1764, 480, 1218,1684, 1653, 311, 1494, 1334, 1601]
if (redmId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[12];
  cupOption.textContent = customLayerNames[12];
  layerDropdown.appendChild(cupOption);


  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[36];
  beerOption.textContent = customLayerNames[36];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[60];
  hoshOption.textContent = customLayerNames[60];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[84];
  deadOption.textContent = customLayerNames[84];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[109];
  ticketOption.textContent = customLayerNames[109];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[133];
  diamondOption.textContent = customLayerNames[133];
  layerDropdown.appendChild(diamondOption);


  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[157];
  beltOption.textContent = customLayerNames[157];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[181];
  newCupOption.textContent = customLayerNames[181];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[205];
  rumOption.textContent = customLayerNames[205];
  layerDropdown.appendChild(rumOption);

}
const darkId = [1416, 1750, 364, 1085, 1057, 91,1816, 370, 587, 1770, 359, 1112,242, 1317, 1721, 821, 1819, 1621, 1456, 1177, 34, 1727, 361, 1758,618,841,318,1529,1381,1710,1522,1571,155,823,547,523,864,868,628,1828,638,498,152,1178,1487,1457,470,1846,588,913,1232,265,647,1751,170,188,1350,1064,308,222,1418,544,746,285,614,1564,1072,986,1693,894,371,846,1439,1293,942,1794,1767,280]
if (darkId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[13];
  cupOption.textContent = customLayerNames[13];
  layerDropdown.appendChild(cupOption);


  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[37];
  beerOption.textContent = customLayerNames[37];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[61];
  hoshOption.textContent = customLayerNames[61];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[85];
  deadOption.textContent = customLayerNames[85];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[110];
  ticketOption.textContent = customLayerNames[110];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[134];
  diamondOption.textContent = customLayerNames[134];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[158];
  beltOption.textContent = customLayerNames[158];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[182];
  newCupOption.textContent = customLayerNames[182];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[206];
  rumOption.textContent = customLayerNames[206];
  layerDropdown.appendChild(rumOption);
  

}
const platinumId = [173,236,1646,1825,1792,1633,45,163,1605,337,317,949,773,1417,1153,1226,1440,73,1022,469,551,539,312,749,1755,352,1011,17,1303,9,154,1865,244,1476,1712,1151,1733,1199,1280,1640,146,1874,1526,1791,515,1414,1752,1097,1845,94,1704,779,1590,41,989,1410,290,1843,1346,394,555,478,412]
if (platinumId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[14];
  cupOption.textContent = customLayerNames[14];
  layerDropdown.appendChild(cupOption);


  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[38];
  beerOption.textContent = customLayerNames[38];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[62];
  hoshOption.textContent = customLayerNames[62];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[86];
  deadOption.textContent = customLayerNames[86];
  layerDropdown.appendChild(deadOption);


  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[111];
  ticketOption.textContent = customLayerNames[111];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[135];
  diamondOption.textContent = customLayerNames[135];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[159];
  beltOption.textContent = customLayerNames[159];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[183];
  newCupOption.textContent = customLayerNames[183];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[207];
  rumOption.textContent = customLayerNames[207];
  layerDropdown.appendChild(rumOption);
  

}
const redId = [134, 362, 450, 1513, 548, 1389,422,552,1567,890,28,388,1533,1730,383,1518,712,288,1515,1066,1052,1021,142,186,984,309,6,1692,178,718,1297,50,1013,1779,216,1657,657,649,410,709,1326,1655,1624,731,754,1358,1531,997,1368,1254,1126,295,179,594,84,1542,1499,111,429]
if (redId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[15];
  cupOption.textContent = customLayerNames[15];
  layerDropdown.appendChild(cupOption);


  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[39];
  beerOption.textContent = customLayerNames[39];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[63];
  hoshOption.textContent = customLayerNames[63];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[87];
  deadOption.textContent = customLayerNames[87];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[112];
  ticketOption.textContent = customLayerNames[112];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[136];
  diamondOption.textContent = customLayerNames[136];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[160];
  beltOption.textContent = customLayerNames[160];
  layerDropdown.appendChild(beltOption);


  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[184];
  newCupOption.textContent = customLayerNames[184];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[208];
  rumOption.textContent = customLayerNames[208];
  layerDropdown.appendChild(rumOption);
  
}
const blueId = [219,1703,1509,1028,460,1521,653,1799,169,1146,122,921,1485,1043,1603,238,1648,1506,1203,205,1195,815,495,267,1528,89,1245,719,910,1443,306,1821,1012,950,1140,1073,1321,1282,1244,1367,338,1453,1807,206,631,1627,770]
if (blueId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[16];
  cupOption.textContent = customLayerNames[16];
  layerDropdown.appendChild(cupOption);


  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[40];
  beerOption.textContent = customLayerNames[40];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[64];
  hoshOption.textContent = customLayerNames[64];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[88];
  deadOption.textContent = customLayerNames[88];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[113];
  ticketOption.textContent = customLayerNames[113];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[137];
  diamondOption.textContent = customLayerNames[137];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[161];
  beltOption.textContent = customLayerNames[161];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[185];
  newCupOption.textContent = customLayerNames[185];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[209];
  rumOption.textContent = customLayerNames[209];
  layerDropdown.appendChild(rumOption);
  


}
const galaxyId = [1308, 1047, 1285, 1070, 968, 413,77,1055,428,369,93,1870,738,809,577,840,13,294,1229,161,1435,185,1759,1275,1831,160,648,1427,1026,1175,797,1420,20,1168,193,1122,1384,597,1212]
if (galaxyId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[17];
  cupOption.textContent = customLayerNames[17];
  layerDropdown.appendChild(cupOption);


  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[41];
  beerOption.textContent = customLayerNames[41];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[65];
  hoshOption.textContent = customLayerNames[65];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[89];
  deadOption.textContent = customLayerNames[89];
  layerDropdown.appendChild(deadOption);


  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[114];
  ticketOption.textContent = customLayerNames[114];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[138];
  diamondOption.textContent = customLayerNames[138];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[162];
  beltOption.textContent = customLayerNames[162];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[186];
  newCupOption.textContent = customLayerNames[186];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[210];
  rumOption.textContent = customLayerNames[210];
  layerDropdown.appendChild(rumOption);
  

}
const greenId = [848,1088,335,1498,1040,255,342,888,465,977,423,144,430,557,663,474,228,725,1311,250,33,1544,873,1238,1278,459,860,939,1390,485,1665,270,1214,1120,862,813,289,1031]
if (greenId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[18];
  cupOption.textContent = customLayerNames[18];
  layerDropdown.appendChild(cupOption);


  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[42];
  beerOption.textContent = customLayerNames[42];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[66];
  hoshOption.textContent = customLayerNames[66];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[90];
  deadOption.textContent = customLayerNames[90];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[115];
  ticketOption.textContent = customLayerNames[115];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[139];
  diamondOption.textContent = customLayerNames[139];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[163];
  beltOption.textContent = customLayerNames[163];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[187];
  newCupOption.textContent = customLayerNames[187];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[211];
  rumOption.textContent = customLayerNames[211];
  layerDropdown.appendChild(rumOption);
  

}
const trippyId = [1033,981,108,350,1019,207,153,1659,904,617,881,1402,753,1772,1724,619,635,826,1077,1796,1450,560,1409,442,858,435,931,642,386,616,1406,332,1133,62,665,1107]
if (trippyId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[19];
  cupOption.textContent = customLayerNames[19];
  layerDropdown.appendChild(cupOption);

  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[43];
  beerOption.textContent = customLayerNames[43];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[67];
  hoshOption.textContent = customLayerNames[67];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[91];
  deadOption.textContent = customLayerNames[91];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[116];
  ticketOption.textContent = customLayerNames[116];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[140];
  diamondOption.textContent = customLayerNames[140];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[164];
  beltOption.textContent = customLayerNames[164];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[188];
  newCupOption.textContent = customLayerNames[188];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[212];
  rumOption.textContent = customLayerNames[212];
  layerDropdown.appendChild(rumOption);
  


}
const zombieId = [1537,12,36,177,1524,1006,1737,1555,504,602,273,53,490,604,964,836,456,1353,1160,1667,1530,1760,934,463,1062,1664,101,545,1887,661,736,1876,975,1592,686]
if (zombieId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[20];
  cupOption.textContent = customLayerNames[20];
  layerDropdown.appendChild(cupOption);

  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[44];
  beerOption.textContent = customLayerNames[44];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[68];
  hoshOption.textContent = customLayerNames[68];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[92];
  deadOption.textContent = customLayerNames[92];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[117];
  ticketOption.textContent = customLayerNames[117];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[141];
  diamondOption.textContent = customLayerNames[141];
  layerDropdown.appendChild(diamondOption);


  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[165];
  beltOption.textContent = customLayerNames[165];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[189];
  newCupOption.textContent = customLayerNames[189];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[213];
  rumOption.textContent = customLayerNames[213];
  layerDropdown.appendChild(rumOption);
  

}
const matrixId = [1844,710,923,1313,1462,1035,1400,774,1641,967,677,983,1683,263,685,476,1699,1363,768,1186,675,1108,1037,912,951,1824,441,346,158,1578,514,1695,1490]
if (matrixId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[21];
  cupOption.textContent = customLayerNames[21];
  layerDropdown.appendChild(cupOption);

  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[45];
  beerOption.textContent = customLayerNames[45];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[69];
  hoshOption.textContent = customLayerNames[69];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[93];
  deadOption.textContent = customLayerNames[93];
  layerDropdown.appendChild(deadOption);

  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[118];
  ticketOption.textContent = customLayerNames[118];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[142];
  diamondOption.textContent = customLayerNames[142];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[166];
  beltOption.textContent = customLayerNames[166];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[190];
  newCupOption.textContent = customLayerNames[190];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[214];
  rumOption.textContent = customLayerNames[214];
  layerDropdown.appendChild(rumOption);
  


}
const magmaId = [869,133,1228,1049,447,814,861,1661,761,1486,946,630,1008,966,627,513,940,887,72,1687,393,110]
if (magmaId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[22];
  cupOption.textContent = customLayerNames[22];
  layerDropdown.appendChild(cupOption);

  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[46];
  beerOption.textContent = customLayerNames[46];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[70];
  hoshOption.textContent = customLayerNames[70];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[94];
  deadOption.textContent = customLayerNames[94];
  layerDropdown.appendChild(deadOption);


  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[119];
  ticketOption.textContent = customLayerNames[119];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[143];
  diamondOption.textContent = customLayerNames[143];
  layerDropdown.appendChild(diamondOption);


  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[167];
  beltOption.textContent = customLayerNames[167];
  layerDropdown.appendChild(beltOption);

  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[191];
  newCupOption.textContent = customLayerNames[191];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[215];
  rumOption.textContent = customLayerNames[215];
  layerDropdown.appendChild(rumOption);
  
}
const dragonId = [798,125,477,1305,1813,104,1713,1034,1144,1600,1329,1179,1169,1380,1589,1230,1042,451,313,987,307,758]
if (dragonId.includes(parseInt(nftId))) {
  const cupOption = document.createElement('option');
  cupOption.value = layerImageUrls[23];
  cupOption.textContent = customLayerNames[23];
  layerDropdown.appendChild(cupOption);


  const beerOption = document.createElement('option');
  beerOption.value = layerImageUrls[47];
  beerOption.textContent = customLayerNames[47];
  layerDropdown.appendChild(beerOption);

  const hoshOption = document.createElement('option');
  hoshOption.value = layerImageUrls[71];
  hoshOption.textContent = customLayerNames[71];
  layerDropdown.appendChild(hoshOption);

  const deadOption = document.createElement('option');
  deadOption.value = layerImageUrls[95];
  deadOption.textContent = customLayerNames[95];
  layerDropdown.appendChild(deadOption);


  const ticketOption = document.createElement('option');
  ticketOption.value = layerImageUrls[120];
  ticketOption.textContent = customLayerNames[120];
  layerDropdown.appendChild(ticketOption);

  const diamondOption = document.createElement('option');
  diamondOption.value = layerImageUrls[144];
  diamondOption.textContent = customLayerNames[144];
  layerDropdown.appendChild(diamondOption);

  const beltOption = document.createElement('option');
  beltOption.value = layerImageUrls[168];
  beltOption.textContent = customLayerNames[168];
  layerDropdown.appendChild(beltOption);


  const newCupOption = document.createElement('option');
  newCupOption.value = layerImageUrls[192];
  newCupOption.textContent = customLayerNames[192];
  layerDropdown.appendChild(newCupOption);

  const rumOption = document.createElement('option');
  rumOption.value = layerImageUrls[216];
  rumOption.textContent = customLayerNames[216];
  layerDropdown.appendChild(rumOption);
  

}
    
    layerDropdown.addEventListener('change', function () {
      const selectedLayerImageUrl = layerDropdown.value;
      selectLayer(selectedLayerImageUrl);
    });

    dropdownContainer.appendChild(layerDropdown);
    const imageContainer = document.querySelector('.image-container');
    imageContainer.appendChild(dropdownContainer);

    setShowModal(false); 
  };

  const selectLayer = (imageUrl) => {
    const nftContainer = document.getElementById('nftContainer');
    const existingOverlay = document.querySelector('.layer-overlay');
    if (existingOverlay) {
      nftContainer.removeChild(existingOverlay);
    }
    const layerOverlay = document.createElement('img');
    layerOverlay.src = imageUrl;
    layerOverlay.alt = 'Layer Overlay';

    if (imageUrl === layerImageUrls[0]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[1]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[16]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[17]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[18]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[19]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[20]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[21]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[22]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[23]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[2]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[3]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[4]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[5]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[6]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[7]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[8]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[9]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[10]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[11]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[12]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[13]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[14]) {
      layerOverlay.classList.add('gm-cup-v1');
    } else if (imageUrl === layerImageUrls[15]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[24]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[25]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[26]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[27]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[28]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[29]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[30]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[31]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[32]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[33]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[34]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[35]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[36]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[37]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[38]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[39]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[40]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[41]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[42]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[43]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[44]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[45]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[46]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[47]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[48]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[49]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[50]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[51]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[52]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[53]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[54]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[55]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[56]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[57]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[58]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[59]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[60]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[61]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[62]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[63]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[64]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[65]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[66]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[67]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[68]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[69]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[70]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[71]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[72]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[73]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[74]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[75]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[76]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[77]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[78]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[79]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[80]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[81]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[82]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[83]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[84]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[85]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[86]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[87]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[88]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[89]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[90]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[91]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[92]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[93]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[94]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[95]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[97]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[98]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[99]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[100]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[101]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[102]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[103]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[104]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[105]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[106]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[107]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[108]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[109]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[110]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[111]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[112]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[113]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[114]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[115]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[116]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[117]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[118]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[119]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[120]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[121]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[122]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[123]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[124]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[125]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[126]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[127]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[128]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[129]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[130]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[131]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[132]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[133]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[134]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[135]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[136]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[137]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[138]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[139]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[140]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[141]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[142]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[143]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[144]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[145]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[146]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[147]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[148]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[149]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[150]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[151]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[152]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[153]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[154]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[155]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[156]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[157]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[158]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[159]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[160]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[161]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[162]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[163]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[164]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[165]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[166]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[167]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[168]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[169]) {
      layerOverlay.classList.add('gn-cup-v1');
    }else if (imageUrl === layerImageUrls[170]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[171]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[172]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[173]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[174]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[175]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[176]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[177]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[178]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[179]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[180]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[181]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[182]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[183]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[184]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[185]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[186]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[187]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[188]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[189]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[190]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[191]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[192]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[193]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[194]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[195]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[196]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[197]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[198]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[199]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[200]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[201]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[202]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[203]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[204]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[205]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[206]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[207]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[208]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[209]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[210]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[211]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[212]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[213]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[214]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[215]) {
      layerOverlay.classList.add('gm-cup-v1');
    }else if (imageUrl === layerImageUrls[216]) {
      layerOverlay.classList.add('gm-cup-v1');
    }
  
    layerOverlay.classList.add('layer-overlay');
    nftContainer.appendChild(layerOverlay);
    setSelectedLayerImages([...selectedLayerImages, imageUrl]);
  };
  

  const mergeAndDownload = () => {
    if (selectedLayerImages.length === 0) {
      alert('Please select a layer image');
      return;
    }
  
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    const nftImage = new Image();
    nftImage.crossOrigin = 'anonymous';
    nftImage.onload = function () {
      const canvasSize = Math.max(nftImage.width, nftImage.height);
      canvas.width = canvasSize;
      canvas.height = canvasSize;
  
      const nftX = (canvas.width - nftImage.width) / 2;
      const nftY = canvas.height - nftImage.height;
      ctx.drawImage(nftImage, nftX, nftY);
  
      selectedLayerImages.forEach((layerUrl, index) => {
        const layerImage = new Image();
        layerImage.crossOrigin = 'anonymous';
        layerImage.onload = function () {
          let x, y;
          if (layerUrl === layerImageUrls[0]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          } else if (layerUrl === layerImageUrls[1]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[2]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[3]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[4]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[5]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[6]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[7]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[8]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[9]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[10]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[11]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[12]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[13]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[14]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[15]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[16]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[17]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[18]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[19]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[20]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[21]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[22]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[23]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[24]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[25]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[26]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[27]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[28]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[29]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[30]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[31]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[32]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[33]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[34]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[35]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[36]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[37]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[38]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[39]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[40]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[41]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[42]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[43]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[44]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[45]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[46]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[47]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[48]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[49]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[50]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[51]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[52]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[53]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[54]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[55]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[56]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[57]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[58]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[59]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[60]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[61]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[62]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[63]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[64]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[65]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[66]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[67]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[68]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[69]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[70]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[71]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[72]) {
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        }
        else if (layerUrl === layerImageUrls[73]) {
            
        
          const newWidth = layerImage.width * 0.2; 
          const newHeight = layerImage.height * 0.2; 
      
          const gmCupX = canvas.width - newWidth;
          const gmCupY = canvas.height - newHeight;
      
          ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[74]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[75]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[76]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[77]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[78]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[79]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[80]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[81]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[82]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[83]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[84]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[85]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[86]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[87]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[88]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[89]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[90]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[91]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[92]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[93]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[94]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[95]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
        
          }else if (layerUrl === layerImageUrls[97]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[98]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[99]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[100]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[101]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[102]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[103]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[104]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[105]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[106]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[107]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[108]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[109]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[110]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[111]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[112]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[113]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[114]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[115]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[116]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[117]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[118]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[119]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[120]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }
          else if (layerUrl === layerImageUrls[121]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[122]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[123]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[124]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[125]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[126]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[127]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[128]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[129]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[130]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[131]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[132]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[133]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[134]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[135]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[136]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[137]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[138]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[139]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[140]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[141]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[142]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[143]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[144]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[145]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[146]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[147]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[148]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
          }else if (layerUrl === layerImageUrls[149]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[150]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[151]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[152]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[153]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
          }else if (layerUrl === layerImageUrls[154]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[155]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[156]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[157]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[158]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[159]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[160]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[161]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[162]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
          }else if (layerUrl === layerImageUrls[163]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[164]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[165]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[166]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[167]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[168]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[169]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = canvas.width - newWidth;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[170]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[171]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[172]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[173]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[174]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[175]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[176]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[177]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[178]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[179]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[180]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[181]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[182]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[183]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[184]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[185]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[186]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[187]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[188]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[189]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[190]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[191]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[192]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[193]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[194]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[195]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[196]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[197]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[198]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[199]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[200]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[201]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[202]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[203]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[204]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[205]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[206]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[207]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[208]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[209]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[210]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[211]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[212]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[213]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[214]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[215]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }else if (layerUrl === layerImageUrls[216]) {
            
        
            const newWidth = layerImage.width * 0.2; 
            const newHeight = layerImage.height * 0.2; 
        
            const gmCupX = (canvas.width - newWidth) / 2;
            const gmCupY = canvas.height - newHeight;
        
            ctx.drawImage(layerImage, gmCupX, gmCupY, newWidth, newHeight);
        
        
          }
          ctx.drawImage(layerImage, x, y);
          if (index === selectedLayerImages.length - 1) {
            const link = document.createElement('a');
            link.href = canvas.toDataURL();
            link.download = 'merged_image.png';
            link.click();
          }
        };
        layerImage.src = layerUrl;
      });
    };
    nftImage.src = nftImageUrl;
  };
  
  

  return (
    <div>
      
     <div className="nft-container container">
        <input
          type="text"
          value={nftId}
          onChange={(e) => setNftId(e.target.value)}
          placeholder="Enter NFT ID"
          className="nft-input"
        />
        <button onClick={fetchNFT} className="tools-button">Fetch NFT</button>
        <div className="image-container">
          <div id="nftContainer">
          {nftImageUrl && <img src={nftImageUrl} alt="NFT artwork" />}
          </div>
        </div>
      </div>
      <div id="modal" className="modal" style={{ display: showModal ? 'block' : 'none' }}>
        <div className="modal-content">
          <span id="closeModal" className="close" onClick={() => setShowModal(false)}>&times;</span>
          <p>{modalMessage}</p>
        </div>
      </div>
      <div className="image-container">
        <button onClick={mergeAndDownload} className="tools-button">Download</button>
      </div>
    </div>
  );
}

export default Tool;
