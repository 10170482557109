import { ThemeProvider } from "styled-components";
import Navigation from "../components/Navigation";
import GlobalStyles from "../styles/GlobalStyles";
import { light } from "../styles/Themes";

import Home from "../components/sections/Home";
import About from "../components/sections/About";
import Roadmap from "../components/sections/Roadmap";
//import Showcase from "../components/sections/Showcase"; // commented out because it's not being used
import Team from "../components/sections/Team";
//import Faq from "../components/sections/Faq"; // commented out because it's not being used
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";

import React from "react";

function Main() {
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={light}>
        <Navigation />
        <Home />
        <About />
        <Roadmap />
        {/* <Showcase /> */}
        <Team />
        {/* <Faq /> */}
        <Footer />
        <ScrollToTop />
      </ThemeProvider>
    </>
  );
}

export default Main;
