import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from './pages/Main';
import NewFeature from './pages/NewFeature';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/newfeature" element={<NewFeature />} />
      </Routes>
    </div>
  );
}

export default App;
