import { ThemeProvider } from "styled-components";
import Navigation from "../components/Navigation";
import GlobalStyles from "../styles/GlobalStyles";
import { light } from "../styles/Themes";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import Tool from "../components/Tool";
import React from "react"; 

function NewFeature() {
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={light}>
        <Navigation />
        <Tool />
        <Footer />
        <ScrollToTop />
      </ThemeProvider>
    </>
  );
}

export default NewFeature;
